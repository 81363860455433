<template>
  <div class="d-flex flex-column align-items-center col-12">
    <div class="navbar-container">
      <div v-if="windowWidth >= 500">
        <DesktopNavBar/>
      </div>
      <div v-if="windowWidth < 500">
        <MobileNavBar/>
      </div>
    </div>
    <div class="d-flex flex-column dashboard-container col-12">
      <button @click="logoutClicked()" v-if="windowWidth >= 500" class="logout-button">Logout</button>
      <div class="d-flex flex-row flex-wrap">
        <div class="col-xl-4 col-12 p-2">
          <div class="mt-4 col-10 penrose-dashboard-name-text">Welcome back {{ userData.Name }}!</div>
        </div>
        <div class="col-xl-4 col-12 p-2">
          <div class="d-flex flex-column mt-4">
            <div class="d-flex flex-row justify-content-start section-header col-10">Leave Application:</div>
            <div class="d-flex flex-column align-items-center mt-3">
              <input v-model="dateRangePicked" class="date-picker" placeholder="Select Date..." type="text"
                     id="datepicker" autocomplete="off"/>
              <div class="d-flex flex-column align-content-center justify-content-center mt-3 col-7">
                <div class="d-flex flex-row col-12 button-holder">
                  <div :class="highlightLeaveType('Annual')" @click="leaveTypeClicked('Annual')"
                       class="d-flex flex-column align-items-center justify-content-center col-6">Annual
                  </div>
                  <div :class="highlightLeaveType('Sick')" @click="leaveTypeClicked('Sick')"
                       class="d-flex flex-column align-items-center justify-content-center col-6">Sick
                  </div>
                </div>
              </div>
              <button @click="submitLeaveClicked()" class="submit-leave-button col-6 mt-3">Submit</button>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-12 p-2">
          <div class="d-flex flex-column mt-4">
            <div class="d-flex flex-row justify-content-start section-header col-10">Upcoming Birthdays!</div>
            <div class="d-flex flex-column justify-content-center col-11 mt-3">
              <table class="table-borderless birthday-table col-12">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Birthday</th>
                  <th scope="col">Countdown</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(staff) in upcomingBirthdays.slice(0,3)" :key="staff.id">
                  <td>{{ staff.name }}</td>
                  <td>{{ staff.birthday }}</td>
                  <td>{{ staff.countdown }} days</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="d-flex flex-column mt-4">
            <div class="d-flex flex-row justify-content-start section-header col-10 mb-2">Our Team</div>
          </div>
          <div class="d-flex flex-column col-12" v-for="(staffMember) in staffDetails.slice(0,4)" :key="staffMember.id">
            <div class="d-flex flex-column col-10 ">
              <div class="staff-name mt-2">{{ staffMember.FullName }}</div>
              <div class="mt-1">{{ staffMember.Email }}</div>
              <div class="d-flex flex-row number-birthday-text pb-2">
                <div>{{ this.splitStringEveryThreeCharacters('0' + staffMember.Msisdn.slice(2)) }}</div>
                <div class="divider-line">|</div>
                <div>{{ this.formatDOB(staffMember.DateOfBirth) }}</div>
              </div>
            </div>
          </div>
          <div @click="routeToPage('our-team')" class="mt-2 m-1 text-decoration-underline">View more</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MobileNavBar from "@/views/components/mobile/MobileNavBar";
import DesktopNavBar from "@/views/components/desktop/DesktopNavBar";
import {ref} from 'vue'
import {mapMutations, mapState} from "vuex";
import DashboardService from "@/services/dashboardService";
import moment from 'moment';
import flatpickr from "flatpickr";
import {environmentConfig} from "@/config/environmentConfig";

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: 'Dashboard',
  computed: {
    ...mapState(['userData']),
  },
  components: {MobileNavBar, DesktopNavBar},
  data() {
    return {
      today: new Date(),
      windowWidth: windowWidth,
      staffDetails: [],
      upcomingBirthdays: [],
      dateRangePicked: undefined,
      leaveTypeSelected: 'Annual',
      startDateLeave: undefined,
      endDateLeave: undefined,
      hoursLeave: undefined,
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading']),
    highlightLeaveType(leaveType) {
      return leaveType === this.leaveTypeSelected ? 'leave-type-button-active' : 'leave-type-button-inactive';
    },
    leaveTypeClicked(leaveType) {
      this.leaveTypeSelected = leaveType;
    },
    formatDOB(birthDate) {
      return moment(birthDate).format("D MMMM")
    },
    splitStringEveryThreeCharacters(inputString) {
      let regex = /(\S{1,3})(\S{1,3})/;

      let resultString = inputString.replace(regex, function (match, group1, group2, group3) {
        return (group1 ? group1 + ' ' : '') + (group2 ? group2 + ' ' : '') + (group3 ? group3 + ' ' : '');
      });

      return resultString.trim();
    },
    async getStaffDetails() {
      const response = await DashboardService.getStaffDetails();
      this.staffDetails = this.shuffleArray(response.data);
    },
    setUpcomingBirthdays() {
      this.staffDetails.forEach((staff) => {
        const {FullName, DateOfBirth} = staff;
        const firstName = FullName.split(" ")[0];
        const massagedBirthday = DateOfBirth.substring(0, 10);
        const birthdaySubstring = massagedBirthday.substring(4);
        const year = this.today.getFullYear();
        const updatedBirthday = year + birthdaySubstring;

        const staffBirthday = moment.utc(updatedBirthday);
        const today = moment(this.today);

        const diffDays = staffBirthday.diff(today, 'days');

        if (staffBirthday.isAfter(today, 'day')) {
          this.upcomingBirthdays.push({
            name: firstName,
            birthday: staffBirthday.format('DD MMMM'),
            countdown: diffDays + 1,
          });
        }
      });
      this.sortBirthdaysByMostRecent();
    },
    sortBirthdaysByMostRecent() {
      this.upcomingBirthdays.sort((a, b) => new Date(a.birthday) - new Date(b.birthday));
    },
    async submitLeaveClicked() {
      this.splitDateRange();
      this.durationWorkHours();
      await this.submitLeave();
    },
    durationWorkHours() {
      const startDate = moment(this.startDateLeave, 'YYYY-MM-DD');
      const endDate = moment(this.endDateLeave, 'YYYY-MM-DD');

      this.hoursLeave = (endDate.diff(startDate, 'days') + 1) * 8;
    },
    splitDateRange() {
      const [startDateStr, endDateStr] = this.dateRangePicked.split(' to ');

      const startDate = moment(startDateStr, 'YYYY-MM-DD');
      this.startDateLeave = startDate.format('YYYY-MM-DD');

      if (endDateStr) {
        const endDate = moment(endDateStr, 'YYYY-MM-DD');
        this.endDateLeave = endDate.format('YYYY-MM-DD');
      } else {
        this.endDateLeave = this.startDateLeave;
      }
    },
    async submitLeave() {
      const response = (await DashboardService.submitLeaveApplication(this.userData, this.leaveTypeSelected, this.startDateLeave, this.endDateLeave, this.hoursLeave)).data
      if (response.Application) {
        this.routeToPage('leave-status')
        await this.sendLeaveApplicationSms();
      } else {
        console.log('error leave application: handle')
      }
    },
    async sendLeaveApplicationSms() {
      const {Name, Surname, Department} = this.userData;
      const {leaveTypeSelected, startDateLeave, endDateLeave, hoursLeave} = this;

      const text = `PENROSE NOTIFICATION! ${Name} ${Surname} has requested ${leaveTypeSelected} leave from ${startDateLeave} to ${endDateLeave}. Duration ${this.hoursToDaysConverter(hoursLeave)} day(s). To approve/deny click ${environmentConfig.internalDashboardUrl}`;

      const recipients = {
        Design: {msisdn: 27723602797, name: 'Jared Laggar'},
        default: {msisdn: 27722823477, name: 'Bruce Mitchell'}
      };

      const {msisdn} = recipients[Department] || recipients.default;
      await DashboardService.sendSMS({msisdn, text});
    },
    hoursToDaysConverter(hours) {
      if (hours <= 0) {
        return 0;
      }
      const hoursInADay = 8;
      return Math.ceil(hours / hoursInADay);
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`);
    },
    logoutClicked() {
      this.setUserData(undefined);
      this.routeToPage('/login')
    },
    shuffleArray(array) {
      const newArr = array.slice()
      for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
      }
      return newArr
    },
  },

  mounted() {
    window.addEventListener('resize', handleResize)
    flatpickr('#datepicker', {
      mode: "range",
      inline: true,
      disable: [
        function (date) {
          return (date.getDay() === 6 || date.getDay() === 0);
        }
      ],
      dateFormat: "Y-m-d",
      minDate: "today",
      locale: {
        firstDayOfWeek: 1
      }
    })
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getStaffDetails();
    this.setUpcomingBirthdays();
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.penrose-dashboard-name-text {
  font-size: 60px;
  color: #EE5C36;
  line-height: 1.1;
  margin-left: 30px;
}

.dashboard-container {
  position: relative;
  top: 65px;
  overflow: auto;
  height: 92vh;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 0;
  width: 100vw;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  right: 10px;
  top: 10px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-container::-webkit-scrollbar {
  display: none;
}

.navbar-container {
  position: fixed;
  top: 0;
  z-index: 0;
}

.section-header {
  font-size: 24px;
  margin-left: 5px;
}

.birthday-table {
  background: none;
}

th, .staff-name, .divider-line {
  color: #EE5C36;
}

.divider-line {
  margin-left: 5px;
  margin-right: 5px;
}

.number-birthday-text {
  border-bottom: 1.6px solid #EE5C36;;
}

input {
  text-align: center;
  border: 0;
  background: transparent;
}

.date-picker {
  width: 250px;
}

.button-holder {
  background-color: #FFFFFF;
  border-radius: 14px;
  z-index: 0;
}

.leave-type-button-active {
  background-color: #EE5C36;
  color: #FFFFFF;
  z-index: 1;
}

.leave-type-button-inactive {
  background-color: transparent;
  color: #b4b4b4;
  border: 0;
}

.leave-type-button-active, .leave-type-button-inactive {
  height: 45px;
  border-radius: 14px;
  border: 0;
}

.submit-leave-button {
  height: 45px;
  font-size: 22px;
  background-color: #EE5C36;
  color: #FFFFFF;
  border: 0;
  border-radius: 12px;
}

@media screen and (max-width: 500px ) {
  .penrose-dashboard-name-text {
    font-size: 40px;
  }

}
</style>
